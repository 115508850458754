/* @import url("https://cdn.jsdelivr.net/npm/tailwindcss@latest/dist/tailwind.min.css"); */
/* Top Header */
.top-header.navbar {
    padding-top: 14px;
    padding-bottom: 14px;
    z-index: 1031;
}

.blurred-bg.top-header > nav::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    inset: 0;
    -webkit-filter: blur(2px);
    filter: blur(2px);
    background-color: rgba(255, 255, 255, 0.96);
    z-index: -1;
    /* opacity: 0.9; */
}

.navbar-brand {
    cursor: pointer;
}

.navbar .navbar-nav .nav-item .nav-link {
    color: inherit;
    font-size: 0.875rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    white-space: nowrap;
}

.navbar .navbar-nav .nav-item > #userDropdown::after {
    border-width: 0;
}

.navbar-nav li {
    align-self: center;
}

.top-header .navbar-nav li .nav-link {
    /* width: 40px;
    height: 40px; */
    width: 32px;
    height: 32px;
    line-height: 16px;
    border: 1px solid #ececec;
    border-radius: 40px;
    background-color: #F9F9F9;
}

.com-logo img,
.user-icon {
    object-fit: scale-down;
    overflow: hidden;
    text-wrap: auto;
}

.user-icon {
    border-radius: 50rem;
    font-size: xx-small;
}

.search-box .searchfield {
    border: none;
    height: 30px;
    line-height: 22px;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 0;
    /* max-width: 380px; */
    width: 100%;
    font-size: 14px;
    outline: none;
    color: #4b4c4e;
    background-color: #F9F9F9;
}

.search-box .searchbutton {
    display: inline-block;
    border: none;
    line-height: 14px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background-color: transparent;
}

/* .top-header .navbar-nav.main-links-list>li .dropdown-menu {
    width: fit-content;
} */

.top-header .navbar-nav.main-links-list>li .dropdown-menu ul {
    height: auto;
    max-height: 550px;
    overflow-x: hidden;
}

/* Navbar Mega menu */
.nav-item.dropdown.dropdown-mega .dropdown-menu {
    width: 100%;
    top: 62px;
    /* top: auto; */
    /* left: 5%; */
}

.mega-menu .list-item {
    cursor: pointer;
    transition: background-color 0.5s ease;
}

/* .mega-menu .list-item:hover {
    background-color: #f8f9fa;
} */

.mega-menu .content-item {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease, visibility 0.5s linear 0.5s;
}

.mega-menu .content-item.visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 1s ease, visibility 0.5s linear 0s;
}

.mega-menu .content h5 {
    font-size: 18px;
    margin-bottom: 10px;
}
/* // Navbar Mega menu // */

/* .top-header .main-links-list>li>.btn {
    font-family: "Inter", sans-serif;
} */

/* .top-header li>.with-arrow.dropdown-toggle::before {
    right: 0;
} */
/* // Top Header // */


/* Top Bottom Header  */
.navbar.tb-header {
    padding-top: 8px;
    padding-bottom: 8px;
    top: 41px;
}

/* .navbar .dropdown-menu {
    font-size: 14px;
} */

.navbar .dropdown .dropdown-item {
    cursor: pointer;
}

.navbar .dropdown-item.link-primary:active {
    background-color: transparent;
}

.navbar .dropdown>.btn.show,
.navbar .dropdown>.btn:active {
    outline: none;
    border: none;
}

.top-header .navbar-nav.main-links-list>li.dropdown>.btn.show,
.top-header .navbar-nav.main-links-list>li.dropdown>.btn:active,
.top-header .navbar-nav.main-links-list>li.dropdown>.btn.show:active, 
.top-header .navbar-nav.main-links-list>li.dropdown>.btn.show:hover,
.top-header .navbar-nav.main-links-list>li.dropdown>.btn.show:hover {
    background: #F5F9FF;
    border: 1px solid #317BFF;
    color: #317BFF;
}
/* // Top Bottom Header // */

/* Hero Banner */
.hero-banner {
    padding-top: 72px;
    height: calc(100vh - 8px);
    /* height: 100vh; */
    background-image: url('../images/home/hero-banner-bg.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.banner-title {
    letter-spacing: 1.4px;
    word-spacing: 5.6px;
}

.banner-sub-title {
    font-size: 16px;
    max-width: 80%;
}

.hover-icon-180deg {
    --bs-icon-link-transform: rotate(180deg);
    --bs-icon-link-transform-active: rotate(180deg);
}

.hover-icon-180deg.active>.bi {
    transform: var(--bs-icon-link-transform-active, translate3d(.25em, 0, 0));
}

.hover-icon-90deg {
    --bs-icon-link-transform: rotate(90deg);
}

.hover-icon--45deg {
    --bs-icon-link-transform: rotate(-45deg);
}

.hover-icon-0deg .bi {
    --bs-icon-link-transform: rotate(0deg);
}

.rotate--45deg {
    transform: rotate(-45deg);
}

.rotate-45deg {
    transform: rotate(45deg);
}

.p-list-item .logo-icon {
    min-width: 40px;
    max-height: 40px;
}

.p-list-item .p-list-cont {
    max-width: 92%;
}

.p-list-item .title {
    line-height: 40px;
}

.btn-outline-secondary.icon-link>.bi {
    fill: var(--bs-secondary-rgb);
}
/* Hero Banner END */

/* client slider */
.client-slider h3 {
    background: #fff;
    color: #3498db;
    font-size: 36px;
    line-height: 100px;
    margin: 10px;
    padding: 2%;
    position: relative;
    text-align: center;
}
/* // client slider END // */

/* Services */
.ser-card-img {
    box-shadow: 0px 4px 4px 0px #00000040;
    max-width: 100%;
    height: auto;
}

.ser-card-img.wa-left {
    box-shadow: 0px 10px 15px 0px #0000000A;
}

.ser-card-img.wa-right {
    box-shadow: -10px 10px 25px 0px #00000026;
}

.wa-left {
    border-bottom-left-radius: 1rem;
}

.wa-right {
    border-bottom-right-radius: 1rem;
}

.ser-card-img.ui-right, .ser-card-img.sa-right {
    max-width: 322px;
}
/* // Services // */

/* Products section */
.pro-hover .hover-img {
    top: 66px;
    left: 50px;
    transition: left 0.5s linear, top 0.5s linear;
}

.pro-hover:hover .hover-img {
    top: 4px;
    left: 4px;
}
/* // Products section // */

/* Testimonials */
/* .tm-nav .btn, .tm-nav-item, .tm-nav-img, .tm-nav-cont {
    transform: none;
    will-change: auto;
    transform-origin: 50% 50% 0px;
}*/
.tm-nav .tm-nav-cont {
    width: 0;
    height: auto;
    transition: all .35s ease;
}

/*.tm-nav .active .tm-nav-cont {
    width: 164px;
} */
.tm-nav-img {
    width: 3.125rem;
    height: 3.125rem;
}
.tm-nav-img img, .tm-card .user-img img {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-position: center top;
    object-fit: cover;
}

.tm-card .user-img {
    aspect-ratio: .7665198237885462 / 1;
    width: 350px;
    height: 457px;
}

.tm-card .user-img img {
    object-position: center center;
}

.fill-yellow-400 {
    fill: #ffc107;
    color: #ffc107;
}

.text-gray-300 {
    fill: #6c757d;
    color: #6c757d;
}

.ease-in-out {
    transition-timing-function: cubic-bezier(0.4,0,0.2,1);
}

.duration-500 {
    transition-duration: .5s;
}

.transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4,0,0.2,1);
    transition-duration: 150ms;
}

.translate-x-0 {
    --tw-translate-x: 0px;
}

.-translate-x-full {
    --tw-translate-x: -100%;
}

.translate-x-full {
    --tw-translate-x: 100%;
}
/* // Testimonials // */

/* Video Section */
.video-section .video-card:hover .play-icon {
    color: darkgray;
}
/* // Video Section // */

/* Blog List */
.text-truncate-v {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.truncate-2 {
    -webkit-line-clamp: 2;
}

.truncate-3 {
    -webkit-line-clamp: 3;
}

/* FAQ Section */
.custom-icon .accordion-button::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
    transition: all 0.5s;
}

.custom-icon .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E");
}

.faq .accordion .accordion-item {
    margin-bottom: 1rem;
}

.faq .accordion .accordion-item:last-child {
    margin-bottom: 0;
}

.faq .accordion-item:not(:first-of-type) {
    border-top: 1px solid var(--bs-accordion-border-color);
}

.faq .accordion-item, .faq .accordion-button {
    border-radius: 1rem;
}

.faq .accordion-item:first-of-type>.accordion-header .accordion-button {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.faq .accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.faq .accordion-button {
    font-weight: 500;
}

.faq .accordion-button:focus, .faq .accordion-button:focus-visible {
    box-shadow: none;
}

.faq .accordion-button:not(.collapsed) {
    color: #000000;
    background-color: #FFFFFF;
    box-shadow: none;
}
/* // FAQ Section END // */

/* Contact us page */
.contact-page .hero-banner, .other-pages .hero-banner {
    height: 50vh;
}

.bg-hover-opacity-75:hover {
    --bs-bg-opacity: 0.75;
}
/* // Contact us page // */

/* Product Details */
.nav-pills.nav-pills-gray .nav-link {
    background-color: var(--bs-gray-200);
    color: var(--bs-gray-dark);
    border: 1px solid transparent;
}

.nav-pills.nav-pills-gray .nav-link.active, 
.nav-pills.nav-pills-gray .show>.nav-link {
  background-color: var(--bs-white);
  color: var(--bs-gray-dark);
  font-weight: 500;
  border-color: var(--bs-gray-600);
}

/* Pricing Switch design */
.pricing-switch .fieldset {
    display: inline-block;
    position: relative;
    padding: 4px;
}

.pricing-switch input[type=radio] {
    position: absolute;
    opacity: 0;
    margin: 0;
    visibility: hidden;
}

.pricing-switch label, 
.pricing-switch .switch {
    width: 94px;
    height: 36px;
}

.pricing-switch label {
    position: relative;
    z-index: 1;
    display: inline-block;
    cursor: pointer;
    color: #262626;
    text-transform: none;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    transition-delay: 0.1s;
    transition: color 0.3s ease;
}

.pricing-switch input[type=radio]:checked+label {
    color: #FFFFFF;
    text-decoration: none;
}

.pricing-switch .switch {
    /* floating background */
    top: 5px;
    left: 5px;
    transition: transform 0.3s;
}

.pricing-switch input[type=radio]:checked+label+.switch,
.pricing-switch input[type=radio]:checked+label:nth-of-type(n)+.switch {
    /* use label:nth-of-type(n) to fix a bug on safari with multiple adjacent-sibling selectors*/
    -webkit-transform: translateX(94px);
    -moz-transform: translateX(94px);
    -ms-transform: translateX(94px);
    -o-transform: translateX(94px);
    transform: translateX(94px);
}
/* // Pricing Switch design // */

.price-switch .price {
    line-height: 1;
    margin-bottom: 0;
    letter-spacing: -.01em;
    font-weight: 400;
    display: inline-block;
    font-size: 48px;
}

.price-switch .timeframe {
    letter-spacing: -.01em;
    font-size: 20px;
    font-weight: 400;
    display: inline-block;
}

.pricing-plans .list li {
    padding-bottom: 1rem;
}

.pricing-plans .list li:last-of-type {
    padding-bottom: 0;
}

.pricing-plans .basic .list li::marker {
    color: var(--bs-primary);
}

.pricing-plans .plus .list li::marker {
    color: var(--bs-success);
}

.pricing-plans .premium .list li::marker {
    color: var(--bs-warning);
}
/* // Product Details // */

/* Career page */
.banner-icons .b-icon-1 {
    left: 0%;
    top: 20px;
}

.banner-icons .b-icon-2 {
    right: 0%;
    bottom: 40px;
}

.join-us .job-cards .card:hover {
    border-color: var(--bs-primary);
}
/* // Career page // */

/* About us page */
.aboutus-page .banner-icons .b-icon-1 {
    top: auto;
    bottom: 40px;
}

.aboutus-page .banner-icons .b-icon-2 {
    top: 40px;
    bottom: auto;
}

.team-member-card {
    position: relative;
    overflow: hidden;
    border: none;
    transition: transform 0.3s ease-in-out;
    /* background-color: #c2c6f745; */
}

.team-member-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.team-member-card:hover img {
    transition: 1s;
    transform: scale(1.1);
}

.team-member-card:hover .member-info .member-name {
    letter-spacing: 1px;
    transition: 1s;
}

.team-member-card .member-info .member-name {
    font-weight: 500;
}

.social-icons {
    position: absolute;
    top: 20%;
    right: 0;
    transform: translate(0, -50%);
    background-color: #00000085;
    opacity: 0;
    padding: 10px;
    transition: opacity 0.3s ease-in-out;
}

.team-member-card:hover .social-icons {
    opacity: 1;
}

.social-icons a {
    margin: 8px 0;
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}

/* .member-info{
    border-bottom: 3px solid #062f3c;
} */
/* // About us page // */

/* Responsive Design */
@media (min-width: 576px) {
    
}

@media (min-width: 768px) {
    .sidebar.main-sidebar {
        top: 41px;
        bottom: 0;
    }

    .main-sidebar .offcanvas-md .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
        background-color: transparent !important;
        /* height: calc(100vh - 72px); */
        height: calc(100vh - 63px);
        scrollbar-width: thin;
    }

    .with-sidebar .content-section {
        width: calc(100% - 72px);
        margin-left: 72px;
    }

    /* Contact page */
    .contact-page .contact-form {
        max-width: 620px;
    }
    /* // Contact page // */
}

@media (min-width:992px) {
    .top-header .main-links-list {
        max-width: calc(100vw - 36vw);
    }

    .top-header li>.btn {
        margin-right: 4px;
        /* padding-left: 16px;
        padding-right: 16px; */
    }

    .top-header li>.with-arrow {
        padding-right: 32px;
    }

    .top-header li>.dropdown-toggle::before {
        top: 4px;
    }

    .top-header ul.dropdown-menu>.h-menu-link {
        display: list-item;
    }

    .sidebar.main-sidebar {
        top: 41px;
    }

    .h-lg-auto {
        height: auto;
    }

    .career-page .hero-banner .banner { 
        height: 70vh !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .single-page .hero-banner {
        height: 70vh !important;
    }
}

@media (min-width: 1200px) {
    /* .top-header > nav {
        width: 75%;
    } */
    .privacy-head {
        max-width: 620px;
    }

    .key-fea-item {
        height: calc(100vh - 72px);
    }

    .features .fea-item img {
        width: 100%;
    }
}

@media (min-width:1400px) {
    .top-header ul.navbar-nav>.h-menu-link {
        display: list-item;
    }
}

@media (max-width: 991.98px) {
    /* body {
        padding-top: 64px;
    } */
    
    body, [data-aos] {
        overflow-x: hidden;
    }

    /* Header Mobile menu design */
    .top-header .main-links-list>li {
        align-self: stretch;
    }

    .top-header .navbar-nav.main-links-list>li .btn, 
    .top-header .offcanvas-start .offcanvas-body > .con-btn {
        width: 100%;
        text-align: start;
        margin: 12px auto 0;
    }

    .top-header .navbar-nav.main-links-list>li>.dropdown-toggle::after {
        position: absolute;
        right: 16px;
    }

    .top-header .navbar-nav.main-links-list>li .dropdown-menu {
        width: 100%;
        height: auto;
    }
    /* // Header Mobile menu Design // */

    /* Hero Banner */
    .hero-banner {
        padding-top: 64px;
        min-height: calc(100vh - 8px);
        height: auto;
    }
    /* Hero Banner END */

    .career-page .hero-banner, .single-page .hero-banner {
        min-height: calc(100vh - 30vh);
    }
}

@media (max-width:767.98px) {
    .career-page .hero-banner, .single-page .hero-banner {
        min-height: auto;
    }
}

/* @media (min-height:920px) {
    .hero-banner {
        height: calc(100vh - 8px);
    }
} */